/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'calidation';
import uniq from 'lodash/uniq';

import InfoAction from '@store/actions/information';
import CaptureAction from '@store/actions/capture';
import Address from '@services/Address';
import EditedAction from '@store/actions/edited';

import Page from '@lib/components/v2/Page';
import Modal from '@lib/components/v2/Modal';
import { isAgeEligible } from '@lib/utils/ageEligibility';

import { getAge, getCookie, setCookie, getResAddressValue } from '@lib/Utils';
import { localizedString } from '@languages';
import APIs from '@services/APIs';

import {
  isPassportExpiredBy2Years,
  isEligibleForPassportExpiredLogic,
  isDocumentExpired
} from '@lib/utils/checkExpiredDocument';
import {
  ExpiredID,
  ExpiredIDBy2Years,
  ConfirmAge,
  UnderEligibleAge,
  ConfirmConsent,
  ConfirmGoBack,
  IdNumberNotEditable,
  TooManyRetryAttempts,
  EditTooManyFields,
  SelectAnotherIdException
} from './VerifyDetails.errors';
import { Error500 } from '../../errors';
import { VerifyDetailsContent, ChooseAnotherId } from '../../components/Contents';

class VerifyDetails extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    onExit: PropTypes.func,
    retake: PropTypes.func,
    setFrontIDParams: PropTypes.func,
    idType: PropTypes.string,
    token: PropTypes.string,
    location: PropTypes.string,
    verify: PropTypes.bool,
    flowType: PropTypes.string,
    appConfig: PropTypes.object,
    verifyDetails: PropTypes.object,
    isFlowV2DiffId: PropTypes.bool,
    setAddress: PropTypes.func,
    setIdInfo: PropTypes.func,
    onGoBack: PropTypes.func,
    setEditedFields: PropTypes.func,
    onChooseDiffId: PropTypes.func,
    isOldTurkishDL: PropTypes.bool
  };

  static defaultProps = {
    onNextStep: () => null,
    onExit: () => null,
    retake: () => null,
    idType: 'NZL_DRIVERLICENCE',
    token: '',
    verify: false
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState(props);

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState(props) {
    const { idDetails, addresses, verifyDetails, useIdNumber, editedFields } = props;
    const state = {
      data: {
        checkConfirm: false,
        dateOfBirth: '',
        expiryDate: ''
      },
      extracted: null,
      /* disable primary button */
      disabled: true,
      confirmed: false,
      error: null,
      changes: editedFields,
      loading: false,
      countryOfIssue: '',
      showDetailed: false,
      hasFormErrors: false,
      showConfirmLeaveEditing: false,
      showNoMiddleNameModal: false,
      backup: null,
      addressApiCalls: 0,
      userEdited: false,
      useIdNumber,
      showSelectAnotherIdException: false,
      showChooseAnotherId: false
    };

    if (idDetails) {
      state.data = { ...idDetails, addressData: addresses };
      state.extracted = idDetails;
      state.countryOfIssue = idDetails.countryOfIssue;
      state.showDetailed = idDetails.showDetailed;
    } else {
      state.data = { ...verifyDetails };
      state.extracted = verifyDetails;
      state.countryOfIssue = verifyDetails.countryOfIssue;
    }

    return state;
  }

  async handleConfirm({ fields, isValid }) {
    const {
      location,
      flowType,
      appConfig,
      verifyDetails,
      isFlowV2DiffId,
      setFrontIDParams,
      retake,
      setAddress,
      setIdInfo,
      onGoBack,
      onNextStep
    } = this.props;
    const { data: newData, hasFormErrors, showDetailed, addressApiCalls = 0 } = this.state;

    if (!isValid) {
      this.setState({
        hasFormErrors: true
      });
      return;
    }

    const edited = Object.keys(fields).reduce((result, key) => {
      if (key !== 'homeAddress' && newData[key] !== verifyDetails[key]) {
        result.push(key);
      }
      return result;
    }, []);

    if (
      verifyDetails?.addressData?.homeAddress &&
      verifyDetails?.addressData?.homeAddress !== newData?.addressData?.homeAddress
    ) {
      edited.push('addressData');
    }

    const data = { ...newData, edited };
    const { checkConfirm } = data;
    const btnOk = [
      {
        label: localizedString('ok'),
        onClick: () => this.setState({ error: null })
      }
    ];

    const { FLOW_V2_VERIFY_DETAILS_SHOW_CONSENT = true } = process.env;

    if (FLOW_V2_VERIFY_DETAILS_SHOW_CONSENT && !checkConfirm) {
      const error = {
        component: ConfirmConsent,
        props: {
          buttons: btnOk
        }
      };
      this.setState({
        error
      });
      return;
    }
    if (hasFormErrors) {
      return;
    }
    const { dateOfBirth, expiryDate, cardType, countryCode } = data;

    const { ENABLE_CONFIRM_AGE = true, ELIGIBLE_AGE = 18 } = process.env;

    const age = getAge(dateOfBirth, true);
    if (ENABLE_CONFIRM_AGE && !isAgeEligible(age, ELIGIBLE_AGE)) {
      const error = {
        component: ConfirmAge,
        props: {
          buttons: [
            {
              label: localizedString('no'),
              variant: 'transparent',
              onClick: () => this.setState({ error: null })
            },
            {
              label: localizedString('yes'),
              onClick: () => {
                const error = {
                  component: UnderEligibleAge,
                  props: {}
                };
                this.setState({ error });
              }
            }
          ]
        }
      };
      this.setState({ error });
      return;
    }

    const isPassportExpiredBy2YearsFlag = isPassportExpiredBy2Years(expiryDate, {
      appConfig,
      cardType,
      countryCode
    });
    const isDocumentExpiredFlag = isDocumentExpired(expiryDate);
    const isExpired = isEligibleForPassportExpiredLogic(appConfig, cardType, countryCode)
      ? isPassportExpiredBy2YearsFlag
      : isDocumentExpiredFlag;

    const {
      FLOW_V2_EXPIRED_ID_TRY_AGAIN_MODE = false,
      FLOW_V2_EXPIRED_ID_BY_2_YEARS_TRY_AGAIN_MODE = false,
      REMOVE_ADDRESS_REVIEW = false
    } = process.env;

    let expiredIdButtons = [
      {
        label: localizedString('back'),
        variant: 'transparent',
        onClick: () => {
          this.setState({ error: null });
        }
      },
      {
        label: localizedString('recaptureDocument'),
        onClick: () => {
          setFrontIDParams({});
          retake();
        }
      }
    ];
    if (isPassportExpiredBy2YearsFlag) {
      if (FLOW_V2_EXPIRED_ID_BY_2_YEARS_TRY_AGAIN_MODE) {
        expiredIdButtons = [
          {
            label: localizedString('tryAgain'),
            onClick: () => {
              setFrontIDParams({});
              retake();
            }
          }
        ];
      }
    } else if (FLOW_V2_EXPIRED_ID_TRY_AGAIN_MODE) {
      expiredIdButtons = [
        {
          label: localizedString('tryAgain'),
          onClick: () => {
            setFrontIDParams({});
            retake();
          }
        }
      ];
    }
    const error = {
      component: isPassportExpiredBy2YearsFlag ? ExpiredIDBy2Years : ExpiredID,
      props: {
        buttons: expiredIdButtons
      }
    };
    if (isExpired) {
      this.setState({ error });
      return;
    }

    // Store the Data
    let { addressData } = data;
    delete data.addressData;

    // Store details
    this.setState({ loading: true });

    if (REMOVE_ADDRESS_REVIEW) {
      addressData = {};
    } else {
      const addressApiCalls1 = addressData.addressApiCalls || 0;
      addressData.addressApiCalls = addressApiCalls1 + addressApiCalls;
      addressData.fullAddress = showDetailed
        ? getResAddressValue(addressData)
        : addressData.homeAddress;

      if (showDetailed) {
        addressData = Address.formatManualAddress(addressData);
      } else {
        const { FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2 = '' } = process.env;

        let addressCountry = verifyDetails.countryCode;
        if (FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2 !== '') {
          addressCountry = FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2;
        }
        // Validate the address
        const { addressData: addrDataValidated } = await Address.verify(
          addressData.homeAddress,
          addressCountry,
          appConfig.dataProvider
        );
        addressData = {
          ...addressData,
          ...addrDataValidated,
          addressApiCalls: addressData.addressApiCalls + 1
        };
      }
    }

    setAddress({ ...addressData, showDetailed });
    setIdInfo({ ...data, ...addressData, showDetailed });

    const params = {
      ...data,
      addressData,
      location,
      flowType,
      countryCode: verifyDetails.countryCode
    };
    // format date
    APIs.store(params, isFlowV2DiffId)
      .then(({ status, type, msg, dataCheckOnConfirm = false, chooseDiffId = false }) => {
        this.setState({ loading: false });
        if (status !== 'success') {
          if (type === 'cards') {
            this.setState({
              error: {
                issue: msg,
                buttons: [
                  {
                    label: localizedString('cancel'),
                    onClick: () => onGoBack()
                  }
                ]
              }
            });
          } else {
            this.setState({
              error: {
                issue: msg,
                buttons: [
                  {
                    label: localizedString('cancel'),
                    onClick: () =>
                      this.setState({
                        error: null
                      })
                  }
                ]
              }
            });
          }
          return;
        }
        if (chooseDiffId) {
          const error = {
            component: SelectAnotherIdException,
            props: {
              buttons: [
                {
                  label: localizedString('selectId'),
                  onClick: () => {
                    this.setState({ showChooseAnotherId: true, error: null });
                  }
                }
              ]
            }
          };
          this.setState({ error });
          return;
        }
        onNextStep({ dataCheckOnConfirm });
      })
      .catch(({ message }) => {
        console.error(message);
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              retake();
            }
          }
        };
        this.setState({ error });
      });
  }

  /**
   * handle the checkbox button
   * @param {String} id
   * @param {Object} value
   * @return {Void}
   */
  async handleChange(id, value) {
    const { setEditedFields, setFrontIDParams, retake } = this.props;
    const { data, extracted = {}, changes, showDetailed } = this.state;
    const {
      ID_NUMBER_EDITABLE = true,
      ADDITIONAL_RECAPTURE_ALLOWED = null,
      FIELD_EDIT_LIMIT_COUNT = 3,
      DISABLE_BACK_BUTTON_ON_EDIT_TOO_MANY = false
    } = process.env;

    const attempts = parseInt(getCookie('idCaptureAttempt'), 10) || 1;

    const onRecaptureClick = () => {
      setEditedFields([]);
      if (ADDITIONAL_RECAPTURE_ALLOWED && attempts > ADDITIONAL_RECAPTURE_ALLOWED) {
        const error = {
          component: TooManyRetryAttempts,
          props: {
            buttons: [
              {
                label: localizedString('back'),
                large: true,
                shadow: true,
                onClick: () => {
                  setCookie('idCaptureAttempt', 0, -7);
                  /* let changes = [...this.state.changes]
                                    let dlIndex = changes.indexOf('licenceNumber');
                                    let passportIndex = changes.indexOf('passportNumber');
                                    if(dlIndex > 0){
                                        changes.splice(dlIndex, 1);
                                    }
                                    if(passportIndex > 0){
                                        changes.splice(passportIndex, 1);
                                    } */
                  this.setState({ error: null, changes });
                }
              }
            ]
          }
        };

        this.setState({ error });
        return;
      }
      setFrontIDParams({});
      retake();
    };

    const { FLOW_V2_EDIT_TOO_MANY_FIELDS_TRY_AGAIN_MODE = false } = process.env;

    let editTooManyFieldsButtons = [];
    if (FLOW_V2_EDIT_TOO_MANY_FIELDS_TRY_AGAIN_MODE) {
      editTooManyFieldsButtons = [
        {
          label: localizedString('tryAgain'),
          onClick: () => {
            onRecaptureClick();
          }
        }
      ];
    } else {
      editTooManyFieldsButtons = [
        {
          label: localizedString('back'),
          variant: 'transparent',
          onClick: () => this.setState({ error: null })
        },
        {
          label: localizedString('recapture'),
          onClick: () => {
            onRecaptureClick();
          }
        }
      ];

      if (DISABLE_BACK_BUTTON_ON_EDIT_TOO_MANY) {
        editTooManyFieldsButtons.shift();
      }
    }
    const editTooManyFieldsError = () => {
      const error = {
        component: EditTooManyFields,
        props: {
          buttons: editTooManyFieldsButtons
        }
      };
      this.setState({ error });
    };

    // ID number not editable
    if (
      !ID_NUMBER_EDITABLE &&
      (id === 'idNumber' || id === 'licenceNumber' || id === 'passportNumber')
    ) {
      document.activeElement.blur(); // hide keyboard
      const error = {
        component: IdNumberNotEditable,
        props: {
          buttons: [
            {
              label: localizedString('back'),
              variant: 'outline',
              onClick: () => this.setState({ error: null })
            },
            {
              label: localizedString('recapture'),
              onClick: () => {
                onRecaptureClick();
              }
            }
          ]
        }
      };
      this.setState({ error });
      return;
    }

    const { street_number, street_name, suburb, postcode, state_territory, apiDisabled } = value;
    // console.log({id, showDetailed, value})
    if (id === 'addressData' && !apiDisabled && !showDetailed && !value.isMatch) {
      this.setState({
        data: {
          ...data,
          [id]: value
        },
        hasFormErrors: true
      });
    } else if (
      (id === 'addressData' && !apiDisabled && !showDetailed && value.isMatch) ||
      (id === 'addressData' &&
        showDetailed &&
        street_number &&
        street_name &&
        suburb &&
        postcode &&
        state_territory)
    ) {
      this.setState({
        data: {
          ...data,
          [id]: value
        },
        hasFormErrors: false
      });
    } else {
      if (['licenceNumber', 'passportNumber'].includes(id) && data.idNumber) {
        data.idNumber = value;
      }

      this.setState({
        data: {
          ...data,
          [id]: value
        }
      });
    }

    if (id !== 'addressData' && !id.match(/^check/)) {
      let filtered;
      if (value !== extracted[id]) {
        filtered = uniq([...changes, id]);
      } else {
        filtered = changes.filter((key) => {
          return key !== id;
        });
      }

      // Field Edit limit
      if (filtered.length > FIELD_EDIT_LIMIT_COUNT) {
        editTooManyFieldsError();
        return;
      }

      this.setState({ changes: filtered });
      setEditedFields(filtered);
    }
  }

  /**
   * Handle go back
   * @return {Void}
   */
  handleGoBack() {
    const { setFrontIDParams, retake } = this.props;
    const error = {
      component: ConfirmGoBack,
      props: {
        buttons: [
          {
            label: localizedString('cancel'),
            variant: 'outline',
            onClick: () => {
              this.setState({ error: null });
            }
          },
          {
            label: localizedString('yes'),
            onClick: () => {
              setFrontIDParams({});
              retake();
            }
          }
        ]
      }
    };
    this.setState({ error });
  }

  handleFormUpdate = ({ fields, isValid }) => {
    if (Object.keys(fields).length > 0) {
      this.setState({
        hasFormErrors: !isValid
      });
    }
  };

  renderConfirmLeaveChanges = () => {
    const { showConfirmLeaveEditing } = this.state;

    const confirmBtns = [
      {
        label: localizedString('cancel'),
        onClick: () => this.setState({ showConfirmLeaveEditing: false }),
        variant: 'transparent'
      },
      {
        label: localizedString('yesImSure'),
        onClick: () => {
          const state = { ...this.state.backup };
          state.backup = null;
          this.setState({ ...state });
        }
      }
    ];

    return (
      <Modal
        isOpen={showConfirmLeaveEditing}
        heading={localizedString('leaveConfirmation')}
        buttons={confirmBtns}
      >
        {localizedString('willLoseChangesOnScreen')}
      </Modal>
    );
  };

  renderNoMiddleNameModal = () => {
    const { showNoMiddleNameModal } = this.state;
    const confirmBtns = [
      {
        label: localizedString('addNow'),
        variant: 'transparent',
        onClick: () => {
          this.setState({
            showNoMiddleNameModal: false
          });
        }
      },
      {
        label: localizedString('noMiddleName'),
        onClick: () => {
          this.setState({
            showNoMiddleNameModal: false
          });
        }
      }
    ];

    return (
      <Modal
        isOpen={showNoMiddleNameModal}
        heading={localizedString('noMiddleNameQuestion')}
        buttons={confirmBtns}
      >
        {localizedString('noMiddleNameDesc1')}
        <br />
        <br />
        {localizedString('noMiddleNameDesc2')}
      </Modal>
    );
  };

  handleChooseDiffId = (newDoc) => {
    const { onChooseDiffId } = this.props;
    onChooseDiffId(newDoc);
  };

  /**
   * Render the component's markup
   * @return {ReactElement}
   */
  render() {
    const {
      error,
      data,
      loading,
      countryOfIssue,
      showDetailed,
      hasFormErrors,
      userEdited,
      useIdNumber,
      changes,
      showChooseAnotherId
    } = this.state;
    const { component: Error, props: errorProps } = error || {};
    const { idType, onExit, verifyDetails, appConfig, isOldTurkishDL } = this.props;

    const {
      FLOW_V2_VERIFY_DETAILS_SHOW_CONSENT = true,
      FLOW_V2_VERIFY_DETAILS_ACTION_BUTTON = localizedString('detailsCorrect'),
      REMOVE_ADDRESS_REVIEW = false
    } = process.env;

    const footerButtons = [
      {
        label: localizedString('back'),
        variant: 'transparent',
        onClick: onExit,
        dataTestId: 'details-back'
      },
      {
        label: loading ? 'Loading' : FLOW_V2_VERIFY_DETAILS_ACTION_BUTTON,
        type: 'submit',
        disabled:
          (FLOW_V2_VERIFY_DETAILS_SHOW_CONSENT && !data.checkConfirm) ||
          hasFormErrors ||
          (!REMOVE_ADDRESS_REVIEW &&
            data.addressData &&
            !data.addressData.apiDisabled &&
            !showDetailed &&
            !data.addressData.isMatch),
        variant: loading ? 'transparent' : 'secandary',
        loading,
        dataTestId: 'details-confirm'
      }
    ];

    const currentDoc = {
      type: data.cardType
    };

    if (showChooseAnotherId) {
      return <ChooseAnotherId currentDoc={currentDoc} onNextStep={this.handleChooseDiffId} />;
    }

    return (
      <div>
        {Error && <Error {...errorProps} />}
        <Form onUpdate={this.handleFormUpdate} onSubmit={this.handleConfirm}>
          <Page
            buttons={footerButtons}
            footerShadow
            onClose={() => {
              if (changes.length > 0) {
                this.setState({ showConfirmLeaveEditing: true });
              } else {
                this.setState(({ backup }) => ({
                  ...backup,
                  backup: null
                }));
              }
            }}
          >
            <VerifyDetailsContent
              {...data}
              engine4Config={appConfig.engine4 ? appConfig.engine4.FLOW_V2 : null}
              idType={idType}
              useIdNumber={useIdNumber}
              isOldTurkishDL={isOldTurkishDL}
              showDetailed={showDetailed}
              onShowDetailed={() => this.setState({ showDetailed: true })}
              country={verifyDetails.countryCode}
              countryOfIssue={countryOfIssue}
              onChange={(id, value) => {
                if (!userEdited) {
                  this.setState({ userEdited: true });
                }
                this.handleChange(id, value);
              }}
              loading={loading}
            />
            {this.renderConfirmLeaveChanges()}
            {this.renderNoMiddleNameModal()}
          </Page>
        </Form>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyDetails);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ information, capture, appConfig, edited }) {
  return {
    addresses: information.addresses,
    idDetails: information.idDetails,
    frontParams: capture.frontParams,
    editedFields: edited.fields,
    appConfig
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setIdInfo: (data) => dispatch(InfoAction.setIdInfo(data)),
    setAddress: (data) => dispatch(InfoAction.setAddress(data)),
    setFrontIDParams: (data) => dispatch(CaptureAction.setFrontIDParams(data)),
    setEditedFields: (data) => dispatch(EditedAction.setEditedFields(data))
  };
}
