import React from 'react';
import PropTypes from 'prop-types';
import { Page } from '@components';

import classes from './Privacy.style.module.scss';

const Privacy = ({ onAccept, onExit }) => {
  const buttons = [
    { label: 'Exit', variant: 'outline', onClick: onExit },
    { label: 'I Accept', onClick: onAccept }
  ];
  return (
    <Page title="Your privacy" isPrivacy buttons={buttons} className={classes.wrapper}>
      <div className={classes.content}>
        To minimise ID theft, Latitude would like to verify your likeness against your identity
        document through a facial recognition program. To do this, we will use the camera on your
        phone to capture images of your face and your ID document. We require your consent to:
        <ol type="a">
          <li>access your camera to receive the images; and</li>
          <li>receive your location data for security purposes.</li>
        </ol>
        <br />
        This data will be collected by Latitude and may also be used to match against the records of
        a third party ID document service provider and for the purposes of fraud prevention. The
        biometric checking service is provided to us by OCR Labs and your data will be collected,
        stored and used by Latitude as per our privacy policy which can be found at the below links
        for Latitude and GEM Finance:
        <br />
        <br />
        <strong>Latitude Finance (Australia)</strong>
        <br />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.latitudefinancial.com.au/privacy/"
        >
          https://www.latitudefinancial.com.au/privacy/
        </a>
        <br />
        <strong>GEM Finance (New Zealand)</strong>
        <br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.gemfinance.co.nz/privacy/">
          https://www.gemfinance.co.nz/privacy/
        </a>
        <br />
        <br />
        Without your consent, and acceptance of this declaration, you will need to validate your
        identity through another way as accepted by Latitude
        <br />
        <br />
        Please tick the box below and press “Continue” if you agree to all the above.
        <br />
        <br />
        Please note that you may still need to visit a post shop or otherwise verify your identity
        and address in accordance with the requirements of law in the relevant jurisdiction for your
        loan application (as identified below). This is a separate verification:
        <ul>
          <li>
            New Zealand – the Anti-Money Laundering and Countering Financing of Terrorism Act 2009
          </li>
          <li>Australia – the Anti-Money Laundering and Counter-Terrorism Financing Act 2006.</li>
        </ul>
      </div>
    </Page>
  );
};

Privacy.propTypes = {
  onAccept: PropTypes.func,
  onExit: PropTypes.func
};

export default Privacy;
